<template>
    <div class="recruit-main">
        <div class="recruit-banner">
            <img class="show" src="@/static/images/recruit/05_jpg1.jpg">
            <div class="bg animate__animated animate__fadeInUp">
                <div class="title">
                    <p class="title-main">加入我们</p>
                    <p class="title-sub">共创新能源产业辉煌</p>
                </div>
            </div>
        </div>
        <div class="recruit-content">
            <div class="title animate__animated animate__fadeInUp">
                <p class="title-main">人才招聘</p>
                <p class="title-sub">Talent recruitment</p>
            </div>
            <div class="content-main animate__animated animate__fadeInUp">
                <div class="content-img">
                    <img src="../static/images/recruit/05_jpg2.jpg">
                </div>
                <div class="content ">
                    <p class="title">户用业务其他岗位需求</p>
                    <ul>
                        <li>
                            <p class="name">商务部主管</p>
                            <p class="value">熟练使用办公软件、CAD等软件，有较强几何理解能力，2年以上户用光伏电站从业经验，能准确判断项目实施的可行性和风险点。能对代理商进行培训。</p>
                        </li>
                        <li>
                            <p class="name">商务人员</p>
                            <p class="value">熟练使用办公软件，熟悉资料整理，代理商沟通。</p>
                        </li>
                        <li>
                            <p class="name">设计验收主管</p>
                            <p class="value">结构、电气专业，会使用CAD、SHADOW等软件。有较强几何理解能力，2年以上户用光伏电站从业经验，能准确判断项目建设安全性、合理性。</p>
                        </li>
                        <li>
                            <p class="name">计审核人员</p>
                            <p class="value">会使用CAD、SHADOW等软件，有较强的沟通能力、理解能力和责任心。</p>
                        </li>
                        <li>
                            <p class="name">验收人员</p>
                            <p class="value">会使用CAD等软件，有较强的责任心，对细节要求严格。</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import foot from "../components/foot";

    export default {
        name: "Recruit",
        components: {foot},
        mounted() {
            this.$bus.$emit("headerFixed", false)
        }
    }
</script>

<style lang="less" scoped>
    .recruit-main {
        height: 150vh;

        .recruit-banner {
            font-size: 0px;
            position: relative;
            overflow: hidden;
            min-height: 455px;

            img {
                width: 100%;
            }

            img.show {
                animation-name: changeBiger;
                animation-duration:50s;
                animation-timing-function:linear;
                animation-fill-mode:forwards;
            }

            .bg {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;

                .title {
                    width: 100%;
                    margin-top: 150px;
                    text-align: center;
                    font-size: 45px;
                    font-family: PingFang SC;
                    color: #FFFFFF;


                    .title-main {
                        font-weight: 100;
                    }

                    .title-sub {
                        font-weight: 400;
                    }
                }
            }
        }

        .recruit-content {
            padding:4.5% 18% 10% 18%;
            margin: 0 auto;
            .title{
                .title-main{
                    font-size: 35px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                }
                .title-sub{
                    font-size: 32px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                    opacity: 0.05;
                    margin-bottom: 50px;
                    margin-top: -14px;
                }
            }
            .content-main{
                display: flex;
                .content-img{

                    img {
                        width: 380px;
                    }
                }
                .content{
                    margin-left: 125px;

                    > .title{
                        margin-top: -5px;
                        font-size: 28px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                        padding-bottom: 24px;
                        border-bottom: 1px solid #EFEFEF;
                    }
                    ul{
                        li{
                            list-style: none;
                            display: flex;
                            flex-direction: column;
                            padding: 18px 0px;
                            border-bottom: 1px solid #EFEFEF;

                            .name {
                                font-size: 18px;
                                font-family: PingFang SC;
                                font-weight: 500;
                                color: #333333;
                                line-height: 29px;
                            }

                            .value {
                                font-size: 12px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #333333;
                                line-height: 20px;
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }
    }

    @keyframes changeBiger {
        0% {
            transform: scale(1.3);
        }

        100% {
            transform: scale(1);

        }
    }
</style>
